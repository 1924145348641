import React from 'react'
import styled from 'styled-components'

import { theme } from '@/styles/theme'

type QABadgeProps = {
  color: 'blue' | 'grey'
  type: 'Q' | 'A'
}

export const QABadge = ({ color, type }: QABadgeProps) => {
  return (
    <Wrapper color={color}>
      <Text>{type}</Text>
    </Wrapper>
  )
}

const Wrapper = styled.div<Pick<QABadgeProps, 'color'>>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ color }) =>
    color === 'blue' ? `${theme.colors.supayBlue}` : `${theme.colors.grey}`};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Text = styled.span`
  color: white;
  font-size: 16px;
`
