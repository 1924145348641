import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import styled from 'styled-components'
import sanitizeHtml from '@/lib/sanitize-html/sanitizeHtml'

import { theme } from '@/styles/theme'

import { Layout } from '@/components/layouts/Layout'
import { Title } from '@/components/elements/Title'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { QABadge } from '@/components/elements/QABadge'
import { Seo } from '@/components/Seo'
import ExternalLinkIcon from '@/images/external-link.svg'
import { Faq } from '@/interfaces/faq'
import { MainWrapper } from '@/styles/common'

type FaqContext = Pick<Faq, 'question' | 'answerBody' | 'mediumCategory'>

export default function Draft({ location }: { location: Location }) {
  const { contentId, draftKey } = queryString.parse(location.search)
  const [faq, setFaq] = useState<FaqContext>()
  const headers = {
    'X-MICROCMS-API-KEY': process.env.GATSBY_MICROCMS_API_KEY || '',
  }

  useEffect(() => {
    fetch(
      `https://su-pay.microcms.io/api/v1/faq/${contentId}?draftKey=${draftKey}&fields=question,answerBody,mediumCategory`,
      {
        headers: headers,
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
      })
      .then((json) => {
        setFaq(json)
      })
  }, [])

  if (!faq) return <></>

  const breadCrumbs = [
    {
      name: 'トップ',
      path: '/',
    },
    {
      name: 'よくあるご質問',
      path: '/faq/',
    },
    {
      name: faq.mediumCategory.mediumCategory,
      path: `/faq/${faq.mediumCategory.id}/`,
    },
    {
      name: faq.question,
    },
  ]

  return (
    <Layout appHide>
      <Seo title={`${faq.question} - よくあるご質問`} />
      <Title as="h1" bg={true}>
        よくあるご質問
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <Question>
          <QABadge type="Q" color="blue"></QABadge>
          <Title as="h2" color={theme.colors.supayBlue}>
            {faq.question}
          </Title>
        </Question>
        <Answer>
          {faq.answerBody !== null &&
            faq.answerBody.map((answer, i) => {
              if (answer.fieldId === 'answerNormal') {
                return (
                  <NormalAnswer
                    key={`${answer.fieldId}${i}`}
                    dangerouslySetInnerHTML={{
                      __html: `${sanitizeHtml(answer.answerNormalText)}`,
                    }}
                  />
                )
              } else if (answer.fieldId === 'answerCaution') {
                return (
                  <CautionAnswer key={`${answer.fieldId}${i}`}>
                    <h3>ご注意</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${sanitizeHtml(answer.answerCautionText)}`,
                      }}
                    />
                  </CautionAnswer>
                )
              } else if (answer.fieldId === 'answerHint') {
                return (
                  <HintAnswer
                    key={`${answer.fieldId}${i}`}
                    dangerouslySetInnerHTML={{
                      __html: `${sanitizeHtml(answer.answerHintText)}`,
                    }}
                  />
                )
              } else if (answer.fieldId === 'answerReference') {
                return (
                  <ReferenceAnswer key={`${answer.fieldId}${i}`}>
                    <h3>参考</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${sanitizeHtml(answer.answerReferenceText)}`,
                      }}
                    />
                  </ReferenceAnswer>
                )
              } else if (answer.fieldId === 'answerTable') {
                return (
                  <TableAnswer
                    key={`${answer.fieldId}${i}`}
                    dangerouslySetInnerHTML={{
                      __html: `${sanitizeHtml(answer.answerTableText)}`,
                    }}
                  />
                )
              } else {
                return <></>
              }
            })}
        </Answer>
      </MainWrapper>
    </Layout>
  )
}

const Question = styled.div`
  display: flex;
  margin: 102px 0 70px;

  div {
    margin-top: 12px;
  }

  h2 {
    line-height: 52px;
    width: calc(100% - 48px);
    text-align: left;
    margin-left: 18px;
  }

  @media screen and (max-width: 1047px) {
    margin: 52px 0 40px;

    div {
      margin-top: -4px;
    }

    h2 {
      line-height: 24px;
      font-size: 20px;
      margin-left: 10px;
      width: calc(100% - 40px);
    }
  }
`

const Answer = styled.section`
  ul {
    margin-top: 12px;

    li {
      display: flex;

      &::before {
        content: '・';
        color: ${(props) => props.theme.colors.supayBlue};
        font-weight: bold;
      }
    }
  }

  ol {
    counter-reset: item 0;
    margin-top: 12px;

    li {
      text-indent: -32px;
      margin-left: 32px;

      &::before {
        content: counter(item) '.';
        counter-increment: item;
        color: ${(props) => props.theme.colors.supayBlue};
        font-weight: bold;
        margin-right: 10px;
      }
    }

    li + li {
      margin-top: 8px;
    }
  }

  a {
    color: ${(props) => props.theme.colors.supayBlue};
    text-decoration: underline;

    &[target='_blank']::after {
      content: '';
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-left: 4px;
      vertical-align: text-top;
      background-size: contain;
      background-image: url(${ExternalLinkIcon});
    }
  }

  @media screen and (max-width: 1047px) {
    img {
      width: 100%;
      object-fit: contain;
    }

    ol li {
      text-indent: -20px;
      margin-left: 20px;
      line-height: 18px;

      &::before {
        margin-right: 8px;
      }

      & + li {
        margin-top: 16px;
      }
    }

    ul li {
      line-height: 18px;

      & + li {
        margin-top: 18px;
      }
    }

    a {
      &[target='_blank']::after {
        width: 16px;
        height: 16px;
      }
    }
  }
`

const NormalAnswer = styled.div`
  font-size: 18px;
  line-height: 36px;

  h3 {
    font-size: 27px;
    line-height: 38px;
    margin: 80px 0 50px;
  }

  h4 {
    font-size: 24px;
    line-height: 34px;
    margin: 80px 0 42px;
    color: ${(props) => props.theme.colors.supayBlue};
  }

  h5 {
    font-size: 22px;
    line-height: 1em;
    margin: 80px 0 40px;
    color: ${(props) => props.theme.colors.textColor};
  }

  @media screen and (max-width: 1047px) {
    font-size: 14px;
    line-height: 22px;

    h3 {
      margin: 40px 0 34px;
      font-size: 18px;
      line-height: 22px;
    }

    h4 {
      margin: 40px 0 34px;
      font-size: 16px;
      line-height: 22px;
    }

    h5 {
      margin: 40px 0 30px;
      font-size: 14px;
      line-height: 22px;
    }
  }
`

const CautionAnswer = styled.div`
  margin-top: 60px;
  padding: 22px 26px;
  background-color: rgba(216, 39, 86, 0.1);
  border-radius: 10px;
  font-size: 20px;

  h3 {
    line-height: 36px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1047px) {
    font-size: 14px;
    padding: 14px 22px;
    margin-top: 32px;

    h3,
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
`

const HintAnswer = styled.div`
  margin-top: 70px;
  padding: 22px 26px;
  background-color: rgba(0, 70, 155, 0.1);
  border-radius: 10px;
  font-size: 20px;

  @media screen and (max-width: 1047px) {
    font-size: 14px;
    padding: 14px 22px;
    margin-top: 32px;
  }
`

const ReferenceAnswer = styled.div`
  margin-top: 60px;
  padding: 22px 26px;
  background-color: rgba(0, 70, 155, 0.1);
  border-radius: 10px;
  font-size: 20px;

  @media screen and (max-width: 1047px) {
    margin-top: 32px;
    font-size: 14px;
    padding: 14px 22px;

    h3,
    ul li {
      font-size: 14px;
      line-height: 20px;
    }
  }
`

const TableAnswer = styled.div`
  margin-top: 60px;

  table,
  th,
  td {
    border: 2px solid rgba(0, 70, 155, 0.1);
    border-collapse: collapse;
  }

  table {
    width: 100%;
  }

  th {
    background-color: rgba(0, 70, 155, 0.1);
    padding: 20px 0;
  }

  td {
    padding: 20px 0;
    text-align: center;
    word-break: break-all;
  }

  @media screen and (max-width: 1047px) {
    font-size: 14px;
    margin-top: 40px;

    th,
    td {
      padding: 10px 2px;
      line-height: 24px;
    }
  }
`
